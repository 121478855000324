import React, { useState } from 'react';
import axios from 'axios';

const EmailScraper = () => {
  const [leads, setLeads] = useState([]);

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    const inputSearchTerm = event.target.elements.url.value;

    try {
      const response = await axios.get('https://serveremailscraper.onrender.com/search-emails', {
        params: {
          searchTerm: inputSearchTerm,
        },
      });
      const data = response.data;

      let formattedLeads = [];

      if (data && data.docs && data.docs.length > 0) {
        formattedLeads = data.docs
          .slice(0, 20)
          .map((doc) => `Email: ${doc.email}, Domain: ${doc.domain}`);
      } else {
        console.log('Invalid response:', data);
      }

      setLeads(formattedLeads);
    } catch (error) {
      console.error(error);
    }

    event.target.reset();
  };

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800"> AI Email Search</h2>
          <form className="flex items-center flex-col" onSubmit={handleGenerateLeads}>
            <input
              type="text"
              name="url"
              placeholder="Enter your search term"
              className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            />
            <button
              type="submit"
              className="w-full h-100 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
            >
              Search
            </button>
          </form>

          <div className="mt-8">
            <textarea
              className="w-full h-96 px-4 py-3 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
              placeholder="Generated Leads"
              value={leads.join('\n')}
              readOnly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailScraper;
