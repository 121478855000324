import React, { useState } from 'react';
import axios from 'axios';

const RedditCard = () => {
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    const searchTerm = event.target.elements.searchTerm.value;

    try {
      const response = await axios.get(`https://serverreddit.onrender.com/reddit-posts/${searchTerm}`);
      const responseData = response.data;

      const formattedLeads = responseData.results.map((result, index) => ({
        position: index + 1,
        url: result.url,
        title: result.title,
        description: result.description,
      }));

      setLeads(formattedLeads);
      setCurrentPage(1);
      event.target.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Pagination
  const leadsPerPage = 1;
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(leads.length / leadsPerPage);

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">Google Leads</h2>
            <form className="flex items-center flex-col" onSubmit={handleGenerateLeads}>
              <input
                type="text"
                name="searchTerm"
                placeholder="Enter search term"
                className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
              />
              <button
                type="submit"
                className="w-full h-100 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
              >
                Generate Leads
              </button>
            </form>
          </div>
          <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
            <div className="mt-8 overflow-x-auto">
              <ul className="space-y-4">
                {currentLeads.map((lead) => (
                  <li key={lead.position} className="border-b pb-4">
                    <p className="text-lg font-bold">Position: {lead.position}</p>
                    <p className="text-blue-600 underline">
                      <a href={lead.url} target="_blank" rel="noopener noreferrer">
                        {lead.url}
                      </a>
                    </p>
                    <p className="text-gray-800">Title: {lead.title}</p>
                    <p className="text-gray-600">Description: {lead.description}</p>
                  </li>
                ))}
              </ul>
              {leads.length === 0 && <p>No leads generated Yet...</p>}
            </div>
            {leads.length > leadsPerPage && (
              <div className="flex justify-between mt-6">
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l focus:outline-none disabled:bg-gray-300 disabled:text-gray-800"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r focus:outline-none disabled:bg-gray-300 disabled:text-gray-800"
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedditCard;
