import React, { useState } from 'react';
import axios from 'axios';

const LeadFinder = () => {
  const [leads, setLeads] = useState([]);

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    const inputUrl = event.target.elements.url.value;
    event.target.reset();

    try {
      const response = await axios.get('https://serverleadfinder.onrender.com/scrape-contacts', {
        params: { query: inputUrl },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      const data = response.data;

      if (data.status === 'OK') {
        const leadData = data.data[0];
        const lead = {
          status: data.status,
          request_id: data.request_id,
          domain: leadData.domain,
          query: leadData.query,
          facebook: leadData.facebook || '',
          instagram: leadData.instagram || '',
          linkedin: leadData.linkedin || '',
          tiktok: leadData.tiktok || '',
          snapchat: leadData.snapchat || '',
          twitter: leadData.twitter || '',
          github: leadData.github || '',
          youtube: leadData.youtube || '',
          pinterest: leadData.pinterest || '',
        };

        setLeads([lead]); // Set only one lead per search
      } else {
        console.log('Failed to retrieve contact information.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Website Contacts</h2>
          <form onSubmit={handleGenerateLeads}>
            <input
              type="text"
              name="url"
              placeholder="Enter the domain to find leads"
              className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            />
            <button
              type="submit"
              className="w-full h-100 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
            >
              Find Leads
            </button>
          </form>

          <div className="mt-1 overflow-x-auto max-h-96 flex flex-wrap w-full">
            {leads.length > 0 ? (
              leads.map((lead, index) => (
                <div key={index} className="mb-4">
                  <h3 className="text-lg font-bold mb-2">Contacts {index + 1}</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Status:</span> {lead.status}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Request ID:</span> {lead.request_id}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Domain:</span> {lead.domain}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Query:</span> {lead.query}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Facebook:</span> {lead.facebook}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Instagram:</span> {lead.instagram}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">LinkedIn:</span> {lead.linkedin}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">TikTok:</span> {lead.tiktok}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Snapchat:</span> {lead.snapchat}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Twitter:</span> {lead.twitter}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Github:</span> {lead.github}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Youtube:</span> {lead.youtube}
                      </p>
                      <p className="text-sm md:text-base">
                        <span className="font-bold">Pinterest:</span> {lead.pinterest}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No leads found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadFinder;
