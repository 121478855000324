import React, { useState } from 'react';
import axios from 'axios';

const EmailGenerator = () => {
  const [recipientEmail, setRecipientEmail] = useState('');
  const [senderName, setSenderName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSendEmail = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://serveremailsender.onrender.com/send-email', {
        email: recipientEmail,
        from_name: senderName,
        subject: subject,
        message: message,
      });
      console.log(response.data);
      setIsEmailSent(true);
    } catch (error) {
      console.error(error);
    }

    setRecipientEmail('');
    setSenderName('');
    setSubject('');
    setMessage('');
  };

  const handleRecipientEmailChange = (event) => {
    setRecipientEmail(event.target.value);
  };

  const handleSenderNameChange = (event) => {
    setSenderName(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Email Sender</h2>
          <form className="flex items-center flex-col" onSubmit={handleSendEmail}>
            <input
              type="text"
              name="recipientEmail"
              placeholder="Recipient Email"
              value={recipientEmail}
              onChange={handleRecipientEmailChange}
              className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            />
            
            <input
              type="text"
              name="subject"
              placeholder="Email Subject"
              value={subject}
              onChange={handleSubjectChange}
              className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            />
            <textarea
              name="message"
              placeholder="Email Message"
              value={message}
              onChange={handleMessageChange}
              className="w-full h-32 px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            ></textarea>
            <button
              type="submit"
              className="w-full h-100 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
            >
              Send Email
            </button>
          </form>

          {isEmailSent && <p className="text-green-500 mt-4">Email Sent!</p>}
        </div>
      </div>
    </div>
  );
};

export default EmailGenerator;
