import React, { useState } from 'react';
import axios from 'axios';

const PhoneLookup = () => {
  const [leads, setLeads] = useState('');

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    const inputNumber = event.target.elements.number.value;

    try {
      const response = await axios.get(`https://serverphonelookup.onrender.com/phone-lookup?number=${inputNumber}`);
      const data = response.data;

      const lead = `Query: ${inputNumber}\nStatus: ${data.status}\nNumber Type: ${data.numberType}\nNumber Valid: ${data.numberValid}\nNumber Valid for Region: ${data.numberValidForRegion}\nNumber Country Code: ${data.numberCountryCode}\nNumber Area Code: ${data.numberAreaCode}\nFormat E164: ${data.formatE164}\nFormat National: ${data.formatNational}\nFormat International: ${data.formatInternational}\nDial from Country Code: ${data.dialFromCountryCode}\nDial from Country Number: ${data.dialFromCountryNumber}\nCarrier: ${data.carrier}\nContinent: ${data.continent}\nContinent Code: ${data.continentCode}\nCountry: ${data.country}\nCountry Name: ${data.countryName}`;
      
      setLeads((prevLeads) => prevLeads + '\n' + lead);
    } catch (error) {
      console.error(error);
    }

    event.target.reset();
  };

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Phone Lookup</h2>
          <form className="flex items-center flex-col" onSubmit={handleGenerateLeads}>
            <input
              type="text"
              name="number"
              placeholder="Enter phone number"
              className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            />
            <button
              type="submit"
              className="w-full h-100 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
            >
              Lookup
            </button>
          </form>

          <div className="mt-8">
            <textarea
              className="w-full h-96 px-4 py-3 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
              placeholder="Generated Leads"
              value={leads}
              readOnly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneLookup;
