import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

const Dashboard = () => {
  const [linkedinLeads, setLinkedinLeads] = useState(() => parseInt(localStorage.getItem('linkedinLeads')) || 0);
  const [instagramLeads, setInstagramLeads] = useState(() => parseInt(localStorage.getItem('instagramLeads')) || 0);
  const [twitterLeads, setTwitterLeads] = useState(() => parseInt(localStorage.getItem('twitterLeads')) || 0);

  const incrementLeadCount = (platform) => {
    if (platform === 'linkedin') {
      const updatedLeads = linkedinLeads + 1;
      setLinkedinLeads(updatedLeads);
      localStorage.setItem('linkedinLeads', updatedLeads.toString());
    } else if (platform === 'instagram') {
      const updatedLeads = instagramLeads + 1;
      setInstagramLeads(updatedLeads);
      localStorage.setItem('instagramLeads', updatedLeads.toString());
    } else if (platform === 'twitter') {
      const updatedLeads = twitterLeads + 1;
      setTwitterLeads(updatedLeads);
      localStorage.setItem('twitterLeads', updatedLeads.toString());
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="flex flex-col flex-1 w-full px-4 py-6 overflow-hidden">
        <div className="overflow-x-auto">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
            <div className="col-span-1 sm:col-span-1 md:col-span-1 xl:col-span-1">
              <div className="scrollable-box">
                <div className="card-container mb-8">
                  <div className="card-content bg-white p-6 md:p-8 rounded-lg shadow-md flex flex-col justify-between h-full">
                    <div>
                      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-black">Twitter</h2>
                    </div>
                    <div className="flex justify-center items-center mb-2">
                      <p className="text-blue-500 font-bold text-lg">Leads</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-blue-500 font-bold text-2xl md:text-3xl">
                        <span className="no-underline">{twitterLeads}</span>
                      </p>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <Link
                        to="/twitter"
                        className="text-black underline text-lg"
                        onClick={() => incrementLeadCount('twitter')}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1 sm:col-span-1 md:col-span-1 xl:col-span-1">
              <div className="scrollable-box">
                <div className="card-container mb-8">
                  <div className="card-content bg-white p-6 md:p-8 rounded-lg shadow-md flex flex-col justify-between h-full">
                    <div>
                      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-black">Instagram</h2>
                    </div>
                    <div className="flex justify-center items-center mb-2">
                      <p className="text-blue-500 font-bold text-lg">Leads</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-blue-500 font-bold text-2xl md:text-3xl">
                        <span className="no-underline">{instagramLeads}</span>
                      </p>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <Link
                        to="/instagram"
                        className="text-black underline text-lg"
                        onClick={() => incrementLeadCount('instagram')}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1 sm:col-span-1 md:col-span-1 xl:col-span-1">
              <div className="scrollable-box">
                <div className="card-container mb-8">
                  <div className="card-content bg-white p-6 md:p-8 rounded-lg shadow-md flex flex-col justify-between h-full">
                    <div>
                      <h2 className="text-2xl md:text-3xl font-bold mb-4 text-black">LinkedIn</h2>
                    </div>
                    <div className="flex justify-center items-center mb-2">
                      <p className="text-blue-500 font-bold text-lg">Leads</p>
                    </div>
                    <div className="flex justify-center items-center">
                      <p className="text-blue-500 font-bold text-2xl md:text-3xl">
                        <span className="no-underline">{linkedinLeads}</span>
                      </p>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <Link
                        to="/linkeldin"
                        className="text-black underline text-lg"
                        onClick={() => incrementLeadCount('linkedin')}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Render nested routes */}
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
