import { useState } from 'react';
import logo from '../assets/Logo.png';

import { auth } from '../config/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

export const Auth = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      onLogin(user); // Notify parent component about successful login
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          onLogin(user); // Notify parent component about successful registration
        } catch (err) {
          console.error(err);
        }
      } else {
        console.error(err);
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-blue-500">
      <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-md">
        <div className="mb-4 flex justify-center">
          <img src={logo} alt="Logo" className="h-16" />
        </div>
        <h2 className="text-3xl mb-4 text-center font-bold">LeadsAI</h2>
        <input
          className="auth-input mb-4 w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-500"
          placeholder="Email..."
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="auth-input mb-4 w-full px-4 py-2 rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-500"
          placeholder="Password..."
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="auth-button mb-2 w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded focus:outline-none focus:ring focus:border-blue-500"
          onClick={signIn}
        >
          Sign In
        </button>
      </div>
    </div>
  );
};
