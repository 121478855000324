// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import LinkeldIn from './pages/LinkeldIn';
import Sidebar from './components/Sidebar';
import Instagram from './pages/Instagram';
import Twitter from './pages/Twitter';
import Reddit from './pages/Reddit';
import LeadFinder from './pages/LeadFinder';
import EmailScraper from './pages/EmailScraper';
import EmailValidator from './pages/EmailValidator';
import PhoneLookup from './pages/PhoneLookup';
import Notepad from './pages/Notepad';
import EmailSender from './pages/EmailSender';
import Support from './pages/Support';
import PhoneValidation from './pages/PhoneValidation';
import Texttospeech from './pages/Texttospeech';
import { Auth } from './components/auth'; // Import the Auth component

import './App.css';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  return (
    <Router>
      {loggedIn ? ( // Render App or Auth based on login state
        <div className="flex">
          <Sidebar />
          <div className="Dashboard">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/linkeldin" element={<LinkeldIn />} />
              <Route path="/instagram" element={<Instagram />} />
              <Route path="/twitter" element={<Twitter />} />
              <Route path="/reddit" element={<Reddit />} />
              <Route path="/leadfinder" element={<LeadFinder />} />
              <Route path="/emailscraper" element={<EmailScraper />} />
              <Route path="/emailvalidator" element={<EmailValidator />} />
              <Route path="/phonelookup" element={<PhoneLookup />} />
              <Route path="/notepad" element={<Notepad />} />
              <Route path="/emailsender" element={<EmailSender />} />
              <Route path="/support" element={<Support />} />
              <Route path="/phonevalidation" element={<PhoneValidation />} />
              <Route path="/texttospeech" element={<Texttospeech />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Auth onLogin={handleLogin} onLogout={handleLogout} /> // Render the Auth component
      )}
    </Router>
  );
};

export default App;
