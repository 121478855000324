import React, { useState } from 'react';
import axios from 'axios';

const TwitterCard = () => {
  const [leads, setLeads] = useState('');

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    const inputUrl = event.target.elements.url.value;
    const username = event.target.elements.username.value; // Get the username from the input
    const userId = event.target.elements.userId.value; // Get the user ID from the input

    try {
      const response = await axios.post('https://apricot-gecko-tie.cyclic.app/twitter-details', {
        username: username,
        user_id: userId
      });
      const data = response.data;

      // Check if the data is an object with the expected properties
      if (typeof data === 'object' && data.username !== undefined && data.name !== undefined) {
        const lead = `Lead: ${inputUrl}`;
        const leadInfo = `Username: ${data.username}\nName: ${data.name}\nFollower Count: ${data.follower_count}\nFollowing Count: ${data.following_count}\nLocation: ${data.location}\nProfile Picture URL: ${data.profile_pic_url}\nDescription: ${data.description}\nExternal URL: ${data.external_url}\nNumber of Tweets: ${data.number_of_tweets}\nBot: ${data.bot}`;

        setLeads((prevLeads) => prevLeads + '\n' + lead + '\n' + leadInfo);
      } else {
        console.error('Invalid data format:', data);
      }
    } catch (error) {
      console.error(error);
    }

    event.target.reset();
  };

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Twitter</h2>
          <form className="flex flex-wrap" onSubmit={handleGenerateLeads}>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <input
                type="text"
                name="url"
                placeholder="Enter your Twitter URL"
                className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800"
              />
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <input
                type="text"
                name="username"
                placeholder="Enter Twitter username"
                className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800"
              />
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <input
                type="text"
                name="userId"
                placeholder="Enter Twitter user ID"
                className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800"
              />
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <button
                type="submit"
                className="w-full h-12 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
              >
                Generate Leads
              </button>
            </div>
          </form>

          <div className="mt-8">
            <textarea
              className="w-full h-60 sm:h-72 md:h-80 lg:h-96 xl:h-108 px-4 py-3 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
              placeholder="Generated Leads"
              value={leads}
              readOnly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitterCard;
