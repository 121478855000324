import React, { useState } from 'react';
import axios from 'axios';

const InstagramCard = () => {
  const [lead, setLead] = useState(null);
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.get(`https://serverinstagram.onrender.com/instagram-user?username=${username}`);
      const data = response.data;

      const {
        full_name,
        is_verified,
        biography,
        edge_followed_by,
        business_email,
        business_phone_number
      } = data;

      const newLead = {
        full_name,
        is_verified,
        biography,
        followers: edge_followed_by.count,
        business_email,
        business_phone_number
      };

      setLead(newLead);
      setUsername('');
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to retrieve Instagram user information.');
    }

    setIsLoading(false);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  return (
    <div className="min-h-screen h-screen lg:w-100 flex items-center justify-center">
      <div className="container mx-auto h-50 px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Instagram</h2>
          <form className="flex items-center flex-col" onSubmit={handleGenerateLeads}>
            <input
              type="text"
              name="username"
              placeholder="Enter Instagram username"
              value={username}
              onChange={handleUsernameChange}
              className="w-full px-4 py-3 h-50 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
              disabled={isLoading}
            />
            <button
              type="submit"
              className={`w-full h-12 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75 ${
                isLoading ? 'opacity-75 cursor-not-allowed' : ''
              }`}
              disabled={isLoading}
            >
              {isLoading ? 'Generating Lead...' : 'Generate Lead'}
            </button>
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
          </form>

          <div className="mt-8">
            <div className="border border-gray-300 rounded-md p-4">
              <h3 className="text-xl font-semibold mb-4">Generated Lead</h3>
              {lead ? (
                <div>
                  <p className="font-semibold">{lead.full_name}</p>
                  <p>{lead.biography}</p>
                  <p>Followers: {lead.followers}</p>
                  <p>Verified: {lead.is_verified ? 'Yes' : 'No'}</p>
                  <p>Business Email: {lead.business_email || 'N/A'}</p>
                  <p>Business Phone Number: {lead.business_phone_number || 'N/A'}</p>
                </div>
              ) : (
                <p className="text-gray-500">No leads generated yet.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramCard;
