import React, { useState, useRef } from 'react';
import axios from 'axios';

const PhoneValidation = () => {
  const [phone, setPhone] = useState('');
  const [result, setResult] = useState(null);
  const textareaRef = useRef(null);

  const handleGenerateLeads = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get('https://serverphonevalidation.onrender.com/validate-phone', {
        params: {
          phone: phone
        }
      });

      const data = response.data;
      setResult(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyLeads = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  };

  const formatData = (data) => {
    let formattedData = '';
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formattedData += `${key}: ${data[key]}\n`;
      }
    }
    return formattedData;
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="container w-full max-w-3xl mx-auto px-4 sm:px-6 md:px-10 lg:px-8">
        <div className="bg-white w-full p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">AI Phone Validation</h2>
          <form onSubmit={handleGenerateLeads}>
            <div className="flex items-center mb-48 ">
              <input
                type="text"
                className=" h-12 w-full md:w-auto px-4 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
                placeholder="Enter phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <button
                type="submit"
                className={`ml-4 w-24 h-12 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75`}
              >
                Validate
              </button>
            </div>
          </form>
          {result && (
            <div className="mt-8">
              <textarea
                ref={textareaRef}
                className="w-full h-96 p-4 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500 resize-none leading-snug"
                placeholder="Generated Leads"
                value={formatData(result)}
                readOnly
              ></textarea>
              <button
                className={`mt-4 w-24 h-15 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75`}
                onClick={handleCopyLeads}
              >
                Copy Result
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneValidation;
