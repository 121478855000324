
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider}  from 'firebase/auth'


const firebaseConfig = {
    apiKey: "AIzaSyBlT3FJ5ou_crgnuTz9NWnc6WKf64wGXwQ",
  authDomain: "leadsai-fded9.firebaseapp.com",
  projectId: "leadsai-fded9",
  storageBucket: "leadsai-fded9.appspot.com",
  messagingSenderId: "354901642025",
  appId: "1:354901642025:web:8914f944662203f4220538"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider();