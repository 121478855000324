import React, { useState } from 'react';
import axios from 'axios';

const EmailValidator = () => {
  const [leads, setLeads] = useState('');

  const handleValidateEmail = async (event) => {
    event.preventDefault();
    const inputEmail = event.target.elements.email.value;

    try {
      const response = await axios.get(`https://serveremailvalidator.onrender.com/validate-email?email=${inputEmail}`);
      const data = response.data;

      // Format the response data
      const formattedData = `Original Email: ${data.originalEmail}\n` +
        `Normalized Email: ${data.normalizedEmail}\n` +
        `Domain: ${data.domain}\n` +
        `Local Part: ${data.localPart}\n` +
        `Is Disposable: ${data.isDisposable ? 'Yes' : 'No'}\n` +
        `Is Valid: ${data.isValid ? 'Yes' : 'No'}\n` +
        `Is Deliverable: ${data.isDeliverable ? 'Yes' : 'No'}\n` +
        `Is Smtp Utf8: ${data.isSmtpUtf8 ? 'Yes' : 'No'}\n` +
        `Message: ${data.message}\n` +
        `ASCII Email: ${data.asciiEmail}\n` +
        `ASCII Local Part: ${data.asciiLocalPart}\n` +
        `ASCII Domain: ${data.asciiDomain}`;

      setLeads(formattedData);
    } catch (error) {
      console.error(error);
    }

    event.target.reset();
  };

  return (
    <div className="min-h-screen h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800"> AI Email Validator</h2>
          <form className="flex items-center flex-col" onSubmit={handleValidateEmail}>
            <input
              type="text"
              name="email"
              placeholder="Enter an email address"
              className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
            />
            <button
              type="submit"
              className="w-full h-100 bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
            >
              Validate
            </button>
          </form>

          <div className="mt-8">
            <textarea
              className="w-full h-96 px-4 py-3 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
              placeholder="Validation Results"
              value={leads}
              readOnly
              style={{
                fontFamily: 'monospace',
                fontWeight: 'bold',
                lineHeight: '1.5',
                letterSpacing: '0.5px',
                whiteSpace: 'pre-wrap',
              }}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailValidator;
