import React, { useState } from 'react';
import axios from 'axios';

const Card = ({ lead }) => (
  <div className="bg-gray-50 rounded-lg p-6 mb-6 shadow-md transform hover:scale-105 transition duration-300">
    <div className="font-bold text-lg mb-4">Name: {lead.Name}</div>
    <div className="text-sm mb-2">Headline: {lead.Headline}</div>
    <div className="text-sm mb-2">Location: {lead.Location}</div>
    <div className="text-sm mb-2">LinkedIn URL: {lead.linkedin_url}</div>
    <div className="text-sm">Company Phone: {lead.company_phone}</div>
  </div>
);

const LinkedInCard = () => {
  const [inputUrl, setInputUrl] = useState('');
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 1;
  const [error, setError] = useState('');

  const handleGenerateLeads = async (event) => {
    event.preventDefault();
    const inputValue = event.target.elements.url.value;

    try {
      // Replace 'http://localhost:3000/generate-leads' with your API endpoint
      const response = await axios.get('https://serverlinkedin.onrender.com/generate-leads');
      const data = response.data;

      if (data.error) {
        setError(data.error);
        return;
      }

      setLeads(data);
      setInputUrl(inputValue);
      event.target.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);

  return (
    <div className="min-h-screen h-screen flex items-center justify-center bg-gradient-to-r to-indigo-500">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-10">
          <div className="max-w-3xl mx-auto bg-white rounded-lg px-6 py-8 overflow-x-auto hide-scroll">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">LinkedIn</h2>
            <form onSubmit={handleGenerateLeads}>
              <input
                type="text"
                name="url"
                placeholder="Enter Username"
                className="w-full px-4 py-3 rounded-md border-2 border-blue-400 focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-800 mb-4"
                value={inputUrl}
                onChange={(event) => setInputUrl(event.target.value)}
              />
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-green-400 to-blue-500 px-6 py-3 rounded-md text-white font-semibold transition duration-300 ease-in-out hover:opacity-75"
              >
                Generate Leads
              </button>
            </form>

            <div className="mt-8">
              {error ? (
                <div className="text-red-500">{error}</div>
              ) : (
                <>
                  {currentLeads.length > 0 ? (
                    <div>
                      <div className="flex flex-wrap -mx-2">
                        {currentLeads.map((lead, index) => (
                          <div key={index} className="w-full sm:w-1/2 lg:w-1/3 px-2">
                            <Card lead={lead} />
                          </div>
                        ))}
                      </div>
                      {leads.length > leadsPerPage && (
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 transition duration-300 hover:bg-blue-600"
                          onClick={handleNextPage}
                        >
                          Next Page
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="text-black-500 text-start text-xl font-bold border-l-black h-48 flex items-top justify-center">
                      No leads found.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInCard;
