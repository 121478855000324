import React, { useState, useRef } from 'react';
import axios from 'axios';

const TextToSpeech = () => {
  const [inputText, setInputText] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const audioRef = useRef(null);

  const handleGenerateAudio = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://servertexttospeech.onrender.com/text-to-speech', {
        text: inputText,
        languageCode: 'en-US',
        voiceName: 'en-US-News-L',
        ssmlGender: 'FEMALE',
        audioEncoding: 'MP3'
      });

      const audioData = response.data.audioContent;
      const audioBlob = base64ToBlob(audioData, 'audio/mp3');
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    setInputText('');
    setAudioUrl('');
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = audioUrl;
    link.download = 'generated_audio.mp3';
    link.click();
  };

  const base64ToBlob = (base64Data, contentType) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Text-To-Speech</h2>
          <form onSubmit={handleGenerateAudio}>
            <div className="mb-4">
              <textarea
                className="w-full h-40 p-4 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500 resize-none leading-snug"
                placeholder="Enter text to convert to speech"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-6">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
              >
                Generate Audio
              </button>
              <button
                type="button"
                className="ml-2 px-4 py-2 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-600"
                onClick={handleReset}
              >
                Reset
              </button>
              {audioUrl && (
                <>
                  <button
                    type="button"
                    className="ml-2 px-4 py-2 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600"
                    onClick={handleDownload}
                  >
                    Download Audio
                  </button>
                  <div>
                    <audio ref={audioRef} src={audioUrl} controls />
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TextToSpeech;
