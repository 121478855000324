import React, { useState, useRef } from 'react';

const LinkedInCard = () => {
  const [leads, setLeads] = useState('');

  const textareaRef = useRef(null);

  const handleGenerateLeads = (event) => {
    event.preventDefault();
    const inputUrl = event.target.elements.url.value;
    const lead = `• ${inputUrl}\n`;
    setLeads((prevLeads) => prevLeads + lead);
    event.target.reset();
  };

  const handleCopyLeads = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  };

  const staticPhone = '+254797974407';
  const staticEmail = 'edwardm5035@gmail.com';

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-2 sm:max-w-md md:max-w-lg lg:max-w-xl">
        <div className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-md">
          <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-2 text-gray-800">Support</h2>
          <div className="mt-4">
            <div className="flex flex-col mb-2">
              <div className="mb-2">
                <span className="font-bold">Phone:</span> {staticPhone}
              </div>
              <div className="mb-2">
                <span className="font-bold">Email:</span> {staticEmail}
              </div>
            </div>
            <textarea
              ref={textareaRef}
              className="w-full h-24 sm:h-32 lg:h-40 p-2 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500 resize-none leading-snug"
              placeholder="Feel free to reach out at any time"
              value={leads}
              onChange={(e) => setLeads(e.target.value)}
              readOnly
            ></textarea>
            <div className="mt-4">
              <button
                className="w-full px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm"
                onClick={handleCopyLeads}
              >
                Get In Touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInCard;
