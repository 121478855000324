import React, { useState, useEffect, useRef } from 'react';

const Notepad = () => {
  const [leads, setLeads] = useState('');
  const textareaRef = useRef(null);

  useEffect(() => {
    const storedLeads = localStorage.getItem('leads');
    if (storedLeads) {
      setLeads(storedLeads);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('leads', leads);
  }, [leads]);

  const handleAddLead = (event) => {
    event.preventDefault();
    const inputUrl = event.target.elements.url.value;
    const lead = `• ${inputUrl}\n`;
    setLeads((prevLeads) => prevLeads + lead);
    event.target.reset();
  };

  const handleCopy = () => {
    textareaRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white p-8 sm:p-10 md:p-12 lg:p-16 xl:p-20 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Notepad</h2>
          <form onSubmit={handleAddLead}>
            <textarea
              ref={textareaRef}
              className="w-full h-96 p-4 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500 resize-none leading-snug"
              placeholder="Generated Leads"
              value={leads}
              onChange={(e) => setLeads(e.target.value)}
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handleCopy}
              >
                Copy
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Notepad;
