import React, { useState } from 'react';
import { FaChartBar, FaHome, FaTwitter, FaShare, FaCheck, FaHeart, FaMusic, FaClipboard, FaFileAudio, FaFileArchive, FaFileWord, FaEnvelope , FaPhoneAlt, FaSearch, FaReddit, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { RiMailOpenLine } from 'react-icons/ri';
import {  SiGoogle } from 'react-icons/si';
import { NavLink } from 'react-router-dom';
import logo from '../assets/Logo.png';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`flex flex-col h-screen bg-gray-900 text-white ${isCollapsed ? 'w-18' : 'w-30'}`}>
      {/* Sidebar Content */}
      <div className="flex-1 overflow-y-auto" style={{ scrollbarWidth: 'thin', msOverflowStyle: 'none' }}>
        {/* Sidebar Logo */}
        <div className="sticky top-0 bg-gray-900 z-10">
          <div className="flex items-center justify-center py-6">
            <img src={logo} alt="Logo" className="h-20 w-20" />
            {!isCollapsed && <span className="ml-2 text-lg font-bold"></span>}
          </div>
        </div>

        {/* Sidebar Menu */}
        <nav>
          <NavLink
            to="/"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'} ${
              isCollapsed ? '' : 'bg-gradient-to-r from-blue-500 to-indigo-500'
            }`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaHome className="h-6 w-6" />
            ) : (
              <>
                <FaHome className="h-6 w-6 mr-3" />
                Dashboard
              </>
            )}
          </NavLink>
          <NavLink
            to="/twitter"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaTwitter className="h-6 w-6" />
            ) : (
              <>
                <FaTwitter className="h-6 w-6 mr-3" />
                Twitter
              </>
            )}
          </NavLink>
          <NavLink
            to="/instagram"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaInstagram className="h-6 w-6" />
            ) : (
              <>
                <FaInstagram className="h-6 w-6 mr-3" />
                Instagram
              </>
            )}
          </NavLink>
           <NavLink
            to="/linkeldin"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaLinkedinIn className="h-6 w-6" />
            ) : (
              <>
                <FaLinkedinIn className="h-6 w-6 mr-3" />
                LinkedIn
              </>
            )}
          </NavLink>
          <NavLink
            to="/reddit"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaReddit className="h-6 w-6" />
            ) : (
              <>
                <FaReddit className="h-6 w-6 mr-3" />
                Google Leads
              </>
            )}
          </NavLink>
          <NavLink
            to="/leadfinder"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaSearch className="h-6 w-6" />
            ) : (
              <>
                <FaSearch className="h-6 w-6 mr-3" />
                Website Contacts
              </>
            )}
          </NavLink>
          <NavLink
            to="/emailscraper"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <RiMailOpenLine className="h-6 w-6" />
            ) : (
              <>
                <RiMailOpenLine className="h-6 w-6 mr-3" />
                Email Search
              </>
            )}
          </NavLink>
          <NavLink
            to="/emailvalidator"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaFileArchive className="h-6 w-6" />
            ) : (
              <>
                <FaFileArchive className="h-6 w-6 mr-3" />
                Email Validator
              </>
            )}
          </NavLink>
          
          <NavLink
            to="/emailsender"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaShare className="h-6 w-6" />
            ) : (
              <>
                <FaShare className="h-6 w-6 mr-3" />
                Email Sender
              </>
            )}
          </NavLink>
          <NavLink
            to="/phonelookup"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaPhoneAlt className="h-6 w-6" />
            ) : (
              <>
                <FaPhoneAlt className="h-6 w-6 mr-3" />
                Phone Lookup
              </>
            )}
          </NavLink>
          <NavLink
            to="/phonevalidation"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaCheck className="h-6 w-6" />
            ) : (
              <>
                <FaCheck className="h-6 w-6 mr-3" />
                Phone Validator
              </>
            )}
          </NavLink>
          <NavLink
            to="/notepad"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaFileWord className="h-6 w-6" />
            ) : (
              <>
                <FaFileWord className="h-6 w-6 mr-3" />
                Notepad
              </>
            )}
          </NavLink>
          <NavLink
            to="/texttospeech"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaMusic className="h-6 w-6" />
            ) : (
              <>
                <FaMusic className="h-6 w-6 mr-3" />
                Text-To-Speech
              </>
            )}
          </NavLink>
          <NavLink
            to="/support"
            className={`flex items-center py-3 px-6 font-bold text-lg ${
              isCollapsed ? 'justify-center' : 'justify-start'
            } text-white no-underline hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 hover:text-white transition duration-300 ${
              isCollapsed ? 'rounded-full' : 'rounded-l-lg'
            } ${isCollapsed ? 'border-transparent' : 'border-blue-500'}`}
            activeClassName="bg-gradient-to-r from-blue-500 to-indigo-500"
          >
            {isCollapsed ? (
              <FaHeart className="h-6 w-6" />
            ) : (
              <>
                <FaHeart className="h-6 w-6 mr-3" />
                Support ?
              </>
            )}
          </NavLink>
        </nav>
      </div>

      {/* Sidebar Toggle Button */}
      <div className="flex items-center justify-center py-3">
        {/* Collapse Button */}
        <button
          className={`flex items-center justify-center py-3 px-6 text-lg text-white no-underline transition duration-300 ${
            isCollapsed
              ? 'bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500'
              : 'bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gray-900'
          }`}
          onClick={handleToggleCollapse}
        >
          {isCollapsed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
